import React, { useState } from "react";
import { Modal } from "antd";
import InputComponent from "../../Inputs/InputComponent2/InputComponent2";
import { useForm } from "react-hook-form";
import { Loading } from "../../Loading/Loading";
import { useMutation, useQuery } from "react-query";
import Api from "../../../common/Api/Api";
import { formatter } from "../../../common/utils/formattPrice";
import { modalSucces } from "../../SweetAlert/Success";
import { modalError } from "../../SweetAlert/Error";

export const AddUser = ({ visible, setVisible }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const [currentPage, setcurrentPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    ["plansBusiness", currentPage],
    () => Api.get("/plan", { page: currentPage, number: 100 })
  );

  const mutation = useMutation(
    (dataPlan) => {
      return Api.post("/email/register", dataPlan);
    },
    {
      onSuccess: (data) => {
        if (data?.ok === false) {
          modalError({
            message: data?.payload.message
              ? data?.payload.message
              : "Revisa tus datos, por favor",
          });
        } else {
          modalSucces({
            message: "La petición se ha realizado de manera exitosa",
            reload: true,
          });
        }
      },
      onError: () => {
        modalError({ message: "Parece que tenemos problemas" });
      },
    }
  );

  const onSubmitEmail = (data) => {
    mutation.mutate(data);
  };

  return (
    <Modal
      title=""
      visible={visible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setVisible(!visible)}
    >
      <form className="add-user" onSubmit={handleSubmit(onSubmitEmail)}>
        <InputComponent
          type="text"
          label="Nombre "
          placeholder=""
          icon="fa fa-user-o"
          className=""
          error={errors.nombre}
          errorMsg="Verifique el campo"
          {...register("nombre", { required: true })}
        />

        <InputComponent
          type="number"
          label="Número de documento "
          placeholder=""
          icon="fa fa-user-o"
          className=""
          error={errors.identificacion}
          errorMsg="Verifique el campo"
          {...register("identificacion", { required: true })}
        />
        <InputComponent
          type="email"
          label="Correo Electrónico "
          placeholder=""
          icon="fa fa-user-o"
          className=""
          error={errors.email}
          errorMsg="Verifique el campo"
          {...register("email", { required: true })}
        />
        <h3>Planes </h3>
        <div className="middle">
          {data?.payload?.items.map((item, index) => (
            <>
              <label>
                <input
                  key={item.personPlanName}
                  value={item?.idpersonPlan}
                  error={errors.plan}
                  errorMsg="Verifique el campo"
                  type="radio"
                  name="radio"
                  {...register("plan", { required: true })}
                />
                <div className="box">
                  <span className="">{item.personPlanName}</span>
                  <p className="text-gray-700 text-4xl font-black">
                    {formatter.format(item.personPlanCost)}
                  </p>
                </div>
              </label>
            </>
          ))}
        </div>
        <input type="submit" className="submit" value="Crear" />
      </form>
      <Loading visible={isLoading || isFetching || mutation.isLoading} />
    </Modal>
  );
};
